import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Stopwatch = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="M18.143 6.693l-.642-.642-1.286 1.285.643.643 1.285-1.286z"
        fill={color}
      />
      <path
        d="M17.499 5.405L15.57 7.333l1.285 1.286 1.928-1.928-1.285-1.286z"
        fill={color}
      />
      <path
        d="M19.36 6.754l-1.928-1.928-.643.643 1.928 1.928.643-.643z"
        fill={color}
      />
      <path
        d="M18.714 8.05l-2.572-2.572 1.286-1.286L20 6.764 18.714 8.05zm-1.286-2.572l1.286 1.286-1.286-1.286zM11.571 20.578C7.396 20.578 4 17.182 4 13.007c0-4.176 3.396-7.572 7.571-7.572 4.176 0 7.572 3.396 7.572 7.572 0 4.175-3.396 7.57-7.572 7.57zm0-14.414a6.85 6.85 0 0 0-6.842 6.843 6.85 6.85 0 0 0 6.842 6.842 6.85 6.85 0 0 0 6.843-6.842 6.85 6.85 0 0 0-6.843-6.843z"
        fill={color}
      />
      <path
        d="M15.018 16.599a.338.338 0 0 1-.231-.086l-3.893-3.198a.362.362 0 0 1-.137-.283V9.774c0-.206.163-.369.368-.369.206 0 .37.163.37.369v3.087l3.763 3.095c.155.129.18.36.052.523a.44.44 0 0 1-.292.12zM12.677 4.397h-2.46v1.398h2.46V4.397z"
        fill={color}
      />
      <path
        d="M14.11 3H8.784v1.398h5.324V3z"
        fill={color}
      />
    </svg>
  );
});

Stopwatch.defaultProps = {
  color: 'currentColor',
};

Stopwatch.displayName = 'Stopwatch';

export default Stopwatch;
