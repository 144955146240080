import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Alerts = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, strokeWidth, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="M6.54 14.45V9.96c0-3.02 2.45-5.47 5.47-5.47 3.02 0 5.47 2.45 5.47 5.47v4.75c0 1.84 1.61 3.78 1.61 3.78H4.91c0-.01 1.63-2.11 1.63-4.04zM12.03 21h-.06c-.84 0-1.53-.68-1.53-1.53v-.62h3.12v.62c0 .85-.69 1.53-1.53 1.53zM12 3c-.64 0-1.17.65-1.17 1.45h2.33C13.17 3.65 12.64 3 12 3z"
        stroke={color}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
});

Alerts.defaultProps = {
  color: 'currentColor',
  strokeWidth: 1,
};

Alerts.displayName = 'Alerts';

export default Alerts;
