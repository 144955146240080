import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Launch = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="M11.531 19.312l-6.062-3.5 4.744-8.217c.688-1.103 2.888-3.378 6.209-3.787.271.752.557 1.866.646 3.086.104 1.426-.067 2.943-.789 4.194l-4.748 8.224z"
        stroke={color}
        strokeLinejoin="round"
      />
      <path stroke={color} strokeLinecap="round" d="M10 14.964l-2.5 4.33" />
      <path
        d="M14.048 10.951a1.5 1.5 0 1 1-2.598-1.5 1.5 1.5 0 0 1 2.598 1.5z"
        stroke={color}
      />
      <path
        d="M6.033 14.406L2.32 12.263c1.228-.836 4.492-2.54 6.647-2.513M12.467 18.12l3.711 2.143c.11-1.482-.046-5.16-1.147-7.013"
        stroke={color}
        strokeLinejoin="round"
      />
    </svg>
  );
});

Launch.defaultProps = {
  color: 'currentColor',
};

Launch.displayName = 'Launch';

export default Launch;
