import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const AlertLarge = forwardRef<SVGSVGElement, IconProps>(({ color, ...props }, ref) => (
  <svg
    width={38}
    height={38}
    viewBox="0 0 38 38"
    fill="none"
    ref={ref}
    {...props}
  >
    <path
      d="M18.134 7.833a1 1 0 011.732 0l11.98 20.75a1 1 0 01-.866 1.5H7.02a1 1 0 01-.866-1.5l11.98-20.75z"
      fill={color}
    />
    <path
      d="M18 25.333a1 1 0 102 0h-2zm2-7.916a1 1 0 10-2 0h2zm0 7.916v-7.916h-2v7.916h2z"
      fill="#fff"
    />
  </svg>
));

AlertLarge.defaultProps = {
  color: '#FF3C64',
};

AlertLarge.displayName = 'AlertLarge';

export default AlertLarge;
