import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const ContractValue = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, strokeWidth, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="M13.556 13.803c0-.36-.126-.662-.38-.909-.25-.246-.674-.468-1.272-.667-.832-.254-1.44-.584-1.822-.99-.379-.41-.568-.93-.568-1.56 0-.64.185-1.165.556-1.575.375-.41.889-.655 1.541-.733V6.086h.873v1.289c.656.09 1.166.365 1.53.826.367.461.55 1.092.55 1.893h-1.078c0-.551-.13-.989-.392-1.313-.262-.324-.616-.486-1.061-.486-.465 0-.82.121-1.066.363-.247.238-.37.572-.37 1.002 0 .399.13.717.387.955.262.235.69.45 1.283.645.598.191 1.063.404 1.395.638.336.23.582.5.738.809.16.309.24.67.24 1.084 0 .66-.199 1.191-.597 1.594-.395.402-.95.64-1.664.715v1.119h-.867v-1.12c-.727-.066-1.297-.324-1.711-.773-.41-.453-.616-1.066-.616-1.84h1.084c0 .543.143.961.428 1.254.285.293.69.44 1.213.44.512 0 .914-.123 1.207-.37.293-.245.44-.582.44-1.007z"
        fill={color}
        strokeWidth={strokeWidth}
      />
      <circle
        cx={12}
        cy={11.75}
        r={8.25}
        stroke={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
});

ContractValue.defaultProps = {
  color: 'currentColor',
};

ContractValue.displayName = 'ContractValue';

export default ContractValue;
